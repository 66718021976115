<template>
  <l-map
    style="height: 350px"
    :zoom="zoom"
    :center="mapLocation"
    @click="addMarker"
    :min-zoom="minZoom"
    :max-bounds="maxBounds"

  >
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker :lat-lng="mapLocation">
      <l-icon class-name="custom-leaflet-map-icon-style">
        <v-icon size="56" color="primary">mli-map-pointer</v-icon>
      </l-icon>
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
export default {
  components: { LMap, LTileLayer, LMarker, LIcon },
  props: ["form"],
  data() {
    return {
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 17,
      center: [25.191999, 55.355398],
      minZoom: 2,
      maxBounds: L.latLngBounds([ 85.03142366737542, -167.95137869021428 ],[ -84.88442800012906, 190.47951089975012 ]),
    };
  },
  computed: {
    mapLocation() {
      if (!this.form.map_location) {
        this.form.map_location = this.center;
      }
      return this.form.map_location;
    },
  },
  mounted() {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  },
  methods: {
    addMarker(event) {
      this.form.map_location = [event.latlng.lat, event.latlng.lng];
    },
  },
};
</script>
<style>
.custom-leaflet-map-icon-style {
  margin-left: -22px !important;
  margin-top: -50px !important;
}
</style>