<template>
  <div style="width: 36vw" class="mt-2 mb-8 text-center rounded pa-5  elevation-2">
    <CoolLightBox
      v-if="menuImages.length"
      :items="menuImages"
      :index="currentImageIndex"
      :fullScreen="true"
      :useZoomBar="true"
      effect="fade"
      @close="currentImageIndex = null"
    >
    </CoolLightBox>

    <v-row>
      <v-col cols="12" class="text-left font-weight-medium py-0 mt-1"> {{ form.location_types.name }} Details </v-col>
      <v-col cols="12" class="py-0 my-0" v-if="form.location_types.name === 'Restaurant'">
        <v-select
          chips
          small-chips
          class="pb-0 pt-1"
          label="Cuisines *"
          multiple
          :items="cuisines"
          v-model="form.cuisines"
          clearable
          :rules="[getRules.required]"
        ></v-select>
      </v-col>

      <v-col cols="12" class="py-0 my-0" v-else>
        <v-select
          chips
          small-chips
          label="Bar Type *"
          :items="barTypes"
          v-model="form.bar_type"
          class="pb-0 pt-1"
          clearable
          :rules="[getRules.required]"
        ></v-select>
      </v-col>

      <v-col cols="6" class="py-0 my-0 mt-2">
        <v-text-field
          label="Seating Capacity *"
          :rules="[getRules.required, getRules.nonNegativeQuantity, getRules.number]"
          class="py-0 my-0"
          v-model="form.seating_capacity"
          min="0"
          clearable
        ></v-text-field>
      </v-col>

      <v-col cols="6" class="text-start py-0 my-0 mt-n1">
        <div>
          <label class="mr-2 text--secondary"><small>Price Range</small></label>
        </div>

        <v-btn-toggle
          class="my-0 py-0"
          mandatory
          v-model="form.price_range"
          :rules="[getRules.required, getRules.nonNegativeQuantity, getRules.number]"
        >
          <v-btn small value="€">€</v-btn>
          <v-btn small value="€€">€€</v-btn>
          <v-btn small value="€€€">€€€</v-btn>
          <v-btn small value="€€€€">€€€€</v-btn>
        </v-btn-toggle>
      </v-col>

      <v-col cols="12" class="pl-2 py-0 my-0 text-left">
        <label class="mr-2 text--secondary"
          ><small>{{ form.location_types.name }} Menu</small></label
        >
      </v-col>

      <v-col class="flex-wrap m-0 pa-0 d-flex">
        <v-card
          v-for="(image, index) in menuImages"
          :key="index"
          :style="{
            'background-image': `url('${image.url}') !important`,
            'background-size': 'cover'
          }"
          color="white"
          class="text-right ma-2 align-center custom-bg-style"
          height="75"
          width="75"
          @click.self="showImage(index)"
        >
          <v-icon
            @click="removeImage(image.id)"
            color="primary"
            class="mdi mdi-close-circle custom-close-button"
          ></v-icon>
        </v-card>
        <v-card
          @click="$refs.menuFiles.click()"
          color="white"
          class="ma-2 d-flex align-center custom-upload-file"
          height="75"
          width="75"
        >
          <div class="text-center flex-grow-1 grey--text text-uppercase">
            <div><i class="mdi mdi-plus"></i></div>
            <div>Upload</div>
            <input style="display: none" type="file" multiple ref="menuFiles" accept="image/*" @change="uploadFiles" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  name: "RestaurantMenuSection",
  components: {
    CoolLightBox
  },

  data: () => ({
    currentImageIndex: null,
    cuisines: [
      "French",
      "Chinese",
      "Japanese",
      "Italian",
      "Greek",
      "Spanish",
      "Mediterranean",
      "Turkish",
      "Thai",
      "Indian",
      "Mexican",
      "Carribean",
      "German",
      "Russian",
      "American",
      "European",
      "Fusion"
    ],

    barTypes: [
      "Rooftop",
      "Beach",
      "Pool",
      "Panorama",
      "Underground",
      "Urban",
      "Hotel",
      "Terrace",
      "Garden",
      "Private",
      "Karaoke",
      "Music",
      "Sports",
      "Beer",
      "Hall",
      "Wine",
      "Cocktail"
    ]
  }),
  props: ["form"],
  computed: {
    menuImages() {
      const vendor = this.getVendor;
      const venueType = this.form.location_types.name.toLowerCase();
      let images = vendor.gallery.length ? vendor.gallery.filter(image => image.property == venueType + "-menu") : [];

      // cool lightbox shows images on the base of src property
      images.forEach(image => {
        image.src = image.url;
      });
      return images;
    },
    ...mapGetters(["getVendor", "getRules"])
  },
  methods: {
    removeImage(id) {
      this.removeMedia({ id });
    },
    showImage(index) {
      this.currentImageIndex = index;
    },
    uploadFiles(event) {
      const files = event.target.files;
      if (!files.length) {
        return;
      }

      this.setLoader(true);
      const formData = new FormData();
      formData.append("location_id", this.form.id);
      formData.append("type", this.form.location_types.name.toLowerCase() + "-menu");
      for (let i = 0; i < files.length; i++) {
        formData.append("files[" + i + "]", files[i]);
      }
      this.$refs.menuFiles.value = "";
      this.uploadToServer(formData);
    },

    uploadToServer(formData) {
      axios
        .post("/api/v1/locations/upload-menu-images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.$store.getters.getAuthToken
          }
        })
        .then(response => {
          this.pushVendorMedia(response.data.images);
          this.setLoader(false);
        });
    },
    ...mapMutations(["setLoader", "pushVendorMedia"]),
    ...mapActions(["removeMedia"])
  }
};
</script>
